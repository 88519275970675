@font-face {
    font-family: dm;
    src: url("../../fonts/dm.ttf");
}

.content{
    font-family: dm;
    height: 100vh;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.bg {
    position: fixed;
    width: 100dvw;
    height: 100dvh;
    z-index: 0;
    animation: 1s zoomIn;
    opacity: .2;
}

@keyframes zoomIn {
    0% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}


.subContent {
    padding-bottom: 100px;
    position: relative;
}

.top {
    width: max-content;
    position: relative;
    z-index: -1;
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
    margin-bottom: 30px;
    text-align: center;
}

.top > h1 {
    font-size: 60px;
    margin: 0;
}

.top > div {
    width: 100%;
    height: 20px;
    background-color: #F8BF92;
    position: absolute;
    bottom: 15px;
    opacity: .5;
}

@keyframes appear {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

.logo {width: 150px; margin: 20px}

@media screen and (max-width: 950px){

    .content{
        height: 85vh;
    }

    .top > h1 {
        font-size: 35px
    }

    .logo {
        width: 100%;
        margin: 0px;
    }

}