.nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    padding-left: 50px;
    padding-right: 50px;
    animation: 1s appear !important;
    z-index: 999;
}

.bg {
    position: fixed;
    width: 100dvw;
    height: 100dvh;
    z-index: 0;
    animation: 1s zoomIn;
    opacity: .2;
}

@keyframes zoomIn {
    0% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes appear {
    0%{
        opacity: 0;
    }
    50%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

.nav > a {
    color: #333;
    text-decoration: none;
    font-size: 20px;
}

.nav > a > h1 {
    margin: 0;
}

.nav > img {
    width: 40px;
    height: auto;
    cursor: pointer;
}

.navOpened{
    height: 100vh;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: absolute;
    background-color: white;
    z-index: 999;
    width: 100%;
}

.navOpened > div {
    animation: 1s appear;
    z-index: 999;
}

.navOpened > div > a {
    display: flex;
    justify-content: center;
    margin: 30px;
    text-decoration: none;
    color: #333;
    transition: .2s;
}

.navOpened > div > a:hover {
    color: #F8BF92;
    transition: .2s;
}

.navOpened > div > a > p {
    font-size: 60px;
    display: block;
    margin: 0;
}

.navOpened > div > a > img {
    width: 30px;
    height: 30px;
    display: block;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 30px;
}

@media screen and (max-width: 950px){

    .nav{
        width: 80%;
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
        padding-left: 0;
        padding-right: 0;
    }

    .nav > a > h1{
        font-size: 20px;
    }

    .nav > img {
        width: 30px;
        height: 30px;
    }

    .navOpened{
        height: 85vh;
    }

    .navOpened > div > a > p {
        font-size: 40px;
    }



}