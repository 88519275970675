@font-face {
    font-family: dm;
    src: url("../../fonts/dm.ttf");
}

.content{
    font-family: dm;
    height: 100vh;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.bg {
    position: fixed;
    width: 100dvw;
    height: 100dvh;
    z-index: 0;
    animation: 1s zoomIn;
    opacity: .2;
}

@keyframes zoomIn {
    0% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}

.titles {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    animation: 2s appear;
    z-index: 10;
}

.box {
    border: 1px solid silver;
    padding: 40px;
    border-radius: 10px;
    width: 300px;
    background-color: transparent;
    backdrop-filter: blur(2px);
}

.box > div {
    display: flex;
    justify-content: center;
    gap: 10px
}

.logo {
    max-width: 80px auto;
    height: 90px
}

@keyframes appear {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

.titles > p{
    font-size: 20px;
}

.top {
    margin: 0;
    font-weight: bolder;
}
.top > h1 {
    font-size: 60px;
    margin: 0;
}

.titles > p {
    text-align: center;
    margin: 0;
}


.top {
    width: max-content;
    position: relative;
    z-index: -1;
    margin: auto;
    display: block;
}

.stacks{
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    justify-content: center;
    margin-top: 50px;
}

.stacks > img {
    width: auto;
    height: 80px;
}

.top > div {
    width: 100%;
    height: 20px;
    background-color: #F8BF92;
    position: absolute;
    bottom: 15px;
    opacity: .5;
}


@media screen and (max-width: 950px){

    .content{
        height: 85vh;
    }


    .top > h1{
        font-size: 40px;
    }

    .top > div {
        bottom: 5px;
        height: 15px;
    }

    .titles > p {
        font-size: 20px;
        width: 85%;
        text-align: center;
        width: 100%;
        margin-top: 20px;
    }

    .titles{
        width: 85%;
        margin: auto;
    }

    .box {
        width: 80%;
    }

    .logo {
        max-width: 60px auto;
        height: 70px
    }

}