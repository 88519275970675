.nav{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    padding-right: 50px;
    padding-left: 50px;
    animation: 2s appear;
    z-index: 90;
}

@keyframes appear {
    0%{
        opacity: 0;
    }
    50%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

.nav > a{
    align-items: center;
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    align-items: center;
}

.nav > a:first-child > .leftArrow, .nav > a:last-child > .rightArrow{
    transition: .4s;
}

.nav > a:first-child:hover > .leftArrow{
    transition: .3s;
    margin-left: -5px;
}

.nav > a:last-child:hover > .rightArrow{
    transition: .3s;
    margin-right: -5px;
}

.nav > a > img {
    width: 40px;
    height: auto;
}

.leftArrow{
    transform: rotate(-180deg);
    z-index: -1;
    margin-right: 15px;
}

.rightArrow{
    margin-left: 15px;
}

.nav > a > p {
    color: #333;
    font-size: 25px;
}

@media screen and (max-width: 950px){

    .nav > a > p {
        font-size: 20px;
    }

    .nav > a > img{
        width: 25px;
    }

}