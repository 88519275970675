.footer {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
}

.footer > div > a:nth-child(1), .footer > div > a:nth-child(2) {
    margin-right: 20px;
}

.footer > div > a{
    text-decoration: none;
    color: #333;
}

.footer > div > a:hover{
    opacity: .8;
}

@media screen and (max-width: 950px){

    .footer{
        flex-direction: column;
        padding-bottom: 20px;
    }

}