.content{
    font-family: dm;
    height: 100vh;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.bg {
    position: fixed;
    width: 100dvw;
    height: 100dvh;
    z-index: 0;
    animation: 1s zoomIn;
    opacity: .2;
}

@keyframes zoomIn {
    0% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}

.titles {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    animation: 1s appear;
    z-index: 10;
}

@keyframes appear {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

.titles > p {
    margin: 0;
    font-size: 30px;
}

.titles > h1 {
    font-size: 50px;
    margin: 0;
    color: #F8BF92;
}

.titles > div {
    display: flex;
    justify-content: center;
    margin: auto;
    margin-top: 10px;
}

.titles > div > a {
    color: #333;
    text-decoration: none;
}

.titles > div > a:hover {
    text-decoration: underline;
}

.titles > div > a:first-child{
    margin-right: 10px;
}

.titles > div > a:last-child{
    margin-left: 10px;
}

@media screen and (max-width: 950px){

    .content{
        height: 85vh;
    }

    .titles > p {
        font-size: 60px;
        text-align: left;
    }

    .titles > h1 {
        font-size: 20px;
        text-align: left;
    }

    .titles > div {
       justify-content: flex-start;
       margin-top: 20px;
    }

}