.content{
    font-family: dm;
    height: 100vh;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.bg {
    position: fixed;
    width: 100dvw;
    height: 100dvh;
    z-index: 0;
    animation: 1s zoomIn;
    opacity: .2;
}

@keyframes zoomIn {
    0% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}

.titles {
    width: 100%;
    animation: 2s appear;
    z-index: 10
}

@keyframes appear {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}


.top {
    width: max-content;
    position: relative;
    z-index: -1;
    margin-left: 10%;
}

.top > h1 {
    font-size: 60px;
    margin: 0;
}

.top > div {
    width: 100%;
    height: 20px;
    background-color: #F8BF92;
    position: absolute;
    bottom: 15px;
    opacity: .5;
}

.projects{
    display: flex;
    justify-content: flex-start;
    overflow-x: scroll;
    margin-top: 30px;
    cursor: default;
    padding-bottom: 50px;
}

/* width */
.projects::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  .projects::-webkit-scrollbar-track {
    background: #F1F1F1;
    margin: 200px;
    border-radius: 10px;
  }
  
  /* Handle */
  .projects::-webkit-scrollbar-thumb {
    background: #dbdbdb;
    border-radius: 10px;
  }
  
  /* Handle on hover */
  .projects::-webkit-scrollbar-thumb:hover {
    background: #d2d2d2;
  }


.projects > div {
    height: 350px;
    min-width: 500px;
    border: 2px solid rgb(230, 230, 230);
    border-radius: 5px;
    margin-right: 20px;
    padding: 50px;
    box-sizing: border-box;
    cursor: pointer;
    transition: .2s;
    backdrop-filter: blur(2px);
}

.projects > div:hover{
    box-shadow: 0 10px 15px rgb(230, 230, 230);
    transition: .5s;
}

.projects > div:first-child{
    margin-left: 10%;
}
.projects > div:last-child{
    margin-right: 50px;
}

.projects > div > div > h1 {
    margin: 0;
    font-size: 40px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 85%;
    white-space: nowrap;
}

.projects > div > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.projects > div > div > h2 {
    font-size: 70px;
    margin: 0;
    color: #F8BF92;
}

.projects > div > p {
    font-size: 20px;
}


@media screen and (max-width: 950px){

    .content{
        height: 85vh;
    }


    .top > h1{
        font-size: 40px;
    }

    .top > div {
        bottom: 5px;
        height: 15px;
    }

    .titles > p {
        font-size: 20px;
        width: 85%;
        text-align: center;
        width: 100%;
        margin-top: 20px;
    }

    .titles{
        width: 100%;
    }

    .projects > div {
        height: 350px;
        min-width: 300px;
        padding: 30px;
    }

    .projects::-webkit-scrollbar {
        display: none;
    }

    .projects > div > div > h1 {
        font-size: 30px;
        margin-left: 20px;
    }

    .projects > div > div > h2 {
        font-size: 50px;
    }

    .projects > div > p {
        font-size: 19px;
    }

}