.content {
    font-family: dm;
    display: flex;
    justify-content: center;
    animation: .7s appear;
}

.left {
    width: 40%;
    height: 100dvh;
    padding: 50px;
    box-sizing: border-box;
}

.back {
    font-size: 20px;
    color: grey;
    text-decoration: none;
    margin-bottom: 30px;
    display: block;
}

.back:hover{
    opacity: .7;
    transition: .5s;
}

.link{
    text-decoration: none;
    color: grey;
    background-color: #e4e4e4;
    width: max-content;
    display: block;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 17px;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    letter-spacing: 3px;
}

.link:hover{
    background-color: #dcdcdc;
}

.title {
    font-size: 60px;
    margin: 0;
}

.left > p {
    font-size: 30px;
    margin:0;
    margin-top: 20px;
    margin-bottom: 30px;
    color: grey;
    text-align: justify;
}

.stacks{
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 20px;
}

@keyframes appear {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

.right {
    width: 60%;
    height: 100dvh;
    background-color: #F2F2F2;
}

.right > img {
    height: 90dvh;
    display: block;
    margin: auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

@media screen and (max-width: 950px){

    .left{
        width: 100%;
        height: 100dvh;
        padding: 40px;
        height: max-content;
    }

    .title {
        font-size: 50px
    }

    .left > p {
        font-size: 25px;
    }

    .stacks {
        justify-content: flex-start;
    }

    .content {
        flex-direction: column;
    }

    .right{
        width: 100%;
        height: max-content;
        box-sizing: border-box;
    }

    .right > img {
        position:static;
        width: 50%;
        height: auto;
        transform: none;
        margin-top: 50px;
    }

}