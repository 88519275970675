@font-face {
    font-family: dm;
    src: url("../../fonts/dm.ttf");
}

@font-face {
    font-family: nothing;
    src: url("../../fonts/nothing.ttf");
}

.bg {
    position: fixed;
    width: 100dvw;
    height: 100dvh;
    z-index: 0;
    animation: 1s zoomIn;
    opacity: .2;
}

@keyframes zoomIn {
    0% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}

.content{
    font-family: dm;
    height: 100vh;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.subContent {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.title {
    font-family: "nothing";
    font-size: 80px;
    margin: 0;
    letter-spacing: .2rem;
}

.title2 {
    font-weight: bold;
    font-size: 30px;
    margin: 0;
}

.subContent > div {
    width: 50%;
}

.subContent > div > p {
    font-size: 25px;
    color: gray;
    text-align: justify;
}

.subContent > img {
    width: 300px;
    height: auto;
    border-radius: 5px;
    border: 3px solid grey;
}

@keyframes appear {
    0%{
        margin-left: 40px;
        opacity: 0;
    }
    100%{
        margin-top: 0;
        opacity: 1;
    }
}


@media screen and (max-width: 950px){

    .content {
        height: 100dvh;
    }

    .subContent{
        flex-direction: column;
    }

    .subContent > div {
        width: 80%;
        box-sizing: border-box;
        overflow: hidden;
    }

    .subContent > img {
        width: 50%;
        margin-top: 50px;
    }

    .title {
        font-size: 60px
    }

    .title2 {
        font-size: 25px
    }

}